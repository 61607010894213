import React, { useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import * as CestaActions from '../../../common/context/actions/cestaActions'
import Layout from '../../../common/components/Layout'
import { SeoData } from '../../../common/types/Seo'
import route from '../../../common/utils/route'
import { t } from '../../../common/i18n'
import { BreadCrumbItems } from '../../../common/components/breadCrumb/LocalizedBreadCrumb'
import AppContext, { IAppState } from '../../../common/context/context'
import { Bateria } from '../type/bateria'
import { PaginationResult } from '../../../shared/catalogo/type/pagination'
import { navigate } from 'gatsby'
import { DataSteps, Steps } from '../../../common/components/cesta/Steps'
import Catalogo from '../../../shared/catalogo/Catalogo'
import { mapBateriaToProducto } from './mapBateriaToProducto'
import { Producto } from '../../../shared/catalogo/producto/producto'
import BateriasFilter from './BateriasFilter'
import { CatalogoProvider } from '../../../shared/catalogo/CatalogoContext'
import {
  BateriasFilters,
  initialBateriaFilterSections,
  initialBateriaFilterValues,
} from './bateriaCatalogoTypes'
import Size from '../../../common/utils/media-queries-setup'
import { catalogoListAction } from '../../../shared/catalogo/store/list/catalogoListActions'
import {
  selectCatalogo,
  selectCatalogoPagination,
} from '../../../shared/catalogo/store/list/catalogoListSelectors'
import { CatalogoSearchParams } from '../../../shared/catalogo/type/filter'
import { BateriasFilterValues } from '../api/bateriasFiltro'
import { catalogoFilterValuesAction } from '../../../shared/catalogo/store/filter/catalogoFilterValuesAction'
import {
  selectCatalogoFilterValues,
  selectCatalogoFilterValuesLoading,
} from '../../../shared/catalogo/store/filter/catalogoFilterValuesSelectors'
import BateriasStaticContent from '../components/BateriasStaticContent'

export interface BateriasProps {
  data: {
    seoData: SeoData
  }
  baterias: Producto[]
  filterValues: BateriasFilterValues
  pagination: PaginationResult
  getBaterias: (data: CatalogoSearchParams<BateriasFilters>) => Promise<void>
  getFilterValues: () => void
}

const breadCrumItems = [
  { display: t('bread_crumb.inicio'), link: route('index') },
  { display: t('bread_crumb.coche'), link: route('coche') },
  { display: t('bread_crumb.baterias') },
] as BreadCrumbItems

export const Baterias: React.FC<BateriasProps> = ({
  data,
  baterias,
  filterValues,
  pagination,
  getBaterias,
  getFilterValues,
}) => {
  const { dispatch } = useContext(AppContext)
  const isMobile = Size.useMedia({
    mobile: true,
    tablet: false,
    desktop: false,
  })

  useEffect(() => {
    getFilterValues()
    getBaterias(initialBateriaFilterValues)
  }, [])

  const onSelectBateria = (bateria: Producto) => {
    dispatch(
      CestaActions.addProduct(
        {
          id: bateria.id_navision,
          type: 'bateria',
        },
        'baterias'
      )
    )
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  const productos = useMemo(
    () => baterias?.map(mapBateriaToProducto),
    [baterias]
  )

  return (
    <Layout
      seoData={data.seoData}
      breadCrumItems={breadCrumItems}
      selector={false}>
      <CatalogoProvider
        name={'baterias'}
        filterSections={{ ...filterValues, ...initialBateriaFilterSections }}
        initialParams={initialBateriaFilterValues}
        getProducts={getBaterias}
        getFilters={getFilterValues}
        isMobile={isMobile}>
        <Catalogo
          title={t('baterias.title')}
          productos={productos}
          pagination={pagination}
          onSelectProducto={onSelectBateria}
          filterComponent={<BateriasFilter />}
        />
      </CatalogoProvider>
      <BateriasStaticContent />
    </Layout>
  )
}
const mapStateToProps = (state: IAppState) => {
  return {
    baterias: selectCatalogo<Bateria>(state, 'baterias'),
    filterValues: selectCatalogoFilterValues(state, 'baterias'),
    pagination: selectCatalogoPagination(state, 'baterias'),
    loadingFilterValues: selectCatalogoFilterValuesLoading(state, 'baterias'),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBaterias: (data: CatalogoSearchParams<BateriasFilters>) =>
      dispatch(catalogoListAction<BateriasFilters>('baterias', data)),
    getFilterValues: () => dispatch(catalogoFilterValuesAction('baterias')),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Baterias)
