import { graphql } from 'gatsby'
import React from 'react'
import Baterias from '../../../../src/coche/baterias/catalogo/Baterias'

const BateriasPage = ({ data }) => <Baterias data={data} />
export default BateriasPage

export const pageQuery = graphql`
  query bateriasQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
