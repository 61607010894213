import React, { ChangeEvent } from 'react'
import styles from '../../../shared/catalogo/filter/filters.module.scss'
import { t } from '../../../common/i18n'
import CheckBoxYellow from '../../../shared/components/Checkbox/CheckBox'
import RadioButtonYellow from '../../../common/components/radioButtonYellow/RadioButtonYellow'
import { BornesBateria, TipoBateria } from '../type/bateria'
import ListFilterSection from '../../../shared/components/List/ListFilterSection/ListFilterSection'
import { useCatalogoContext } from '../../../shared/catalogo/CatalogoContext'
import SpinnerWithText from '../../../common/components/spinnerWithText/SpinnerWithText'

const BateriasFilter = () => {
  const {
    filters,
    searchParams,
    filterChange,
    toggleFilterSection,
    loadingFilterValues,
  } = useCatalogoContext()

  if (loadingFilterValues) {
    return <SpinnerWithText text={t('catalogo.loading')} />
  }

  return (
    <>
      <ListFilterSection
        titleId="baterias.filters.marca"
        expanded={filters.marcasExpanded}
        toggleSection={() => toggleFilterSection('marcasExpanded')}>
        {filters &&
          filters.marcas?.map((marca) => (
            <div className={styles.filterSidebar__label} key={Math.random()}>
              <CheckBoxYellow
                size="xsmall"
                name={marca.id_site_marca_bateria}
                checked={searchParams.filters.marca?.includes(
                  String(marca.id_site_marca_bateria)
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value
                  filterChange(
                    'marca',
                    e.target.checked
                      ? [...searchParams.filters.marca, value]
                      : searchParams.filters.marca.filter((id) => id !== value)
                  )
                }}
                label1={marca.marca}
                value={marca.id_site_marca_bateria}
                key={Math.random()}
              />
            </div>
          ))}
      </ListFilterSection>
      <ListFilterSection
        titleId="baterias.filters.startStop"
        expanded={filters.startStopExpanded}
        toggleSection={() => toggleFilterSection('startStopExpanded')}>
        <div className={styles.filterSidebar__inputList}>
          <div className={styles.filterSidebar__label} key={Math.random()}>
            <RadioButtonYellow
              variant="xsmall"
              key={Math.random()}
              label1="Sí"
              name="start_stop"
              value={true}
              checked={!!searchParams.filters.startStop === true}
              onChange={(value) => filterChange('startStop', value as boolean)}
            />
          </div>
          <div className={styles.filterSidebar__label} key={Math.random()}>
            <RadioButtonYellow
              variant="xsmall"
              key={Math.random()}
              label1="No"
              name="start_stop"
              value={false}
              checked={searchParams.filters.startStop === false}
              onChange={(value) => filterChange('startStop', value as boolean)}
            />
          </div>
        </div>
      </ListFilterSection>
      {/* TIPO_BATERIA */}
      <ListFilterSection
        titleId="baterias.filters.tipo"
        expanded={filters.tipoExpanded}
        toggleSection={() => toggleFilterSection('tipoExpanded')}>
        <div className={styles.filterSidebar__inputList}>
          {filters &&
            filters.tipo_bateria?.map((tipo) => {
              return (
                <div
                  className={styles.filterSidebar__label}
                  key={Math.random()}>
                  <RadioButtonYellow
                    variant="xsmall"
                    label1={t(`baterias.filters.${tipo}`)}
                    name="tipo"
                    key={`tipo_${tipo}`}
                    value={tipo}
                    checked={searchParams.filters.tipo_bateria === tipo}
                    onChange={(value) =>
                      filterChange('tipo_bateria', value as TipoBateria)
                    }
                  />
                </div>
              )
            })}
        </div>
      </ListFilterSection>

      {/* AMPERAJE */}
      <ListFilterSection
        titleId="baterias.filters.amperaje"
        expanded={filters.amperajeExpanded}
        toggleSection={() => toggleFilterSection('amperajeExpanded')}>
        <div className={styles.filterSidebar__inputList}>
          {filters &&
            filters.amperaje?.map((amperaje) => {
              return (
                <div
                  className={styles.filterSidebar__label}
                  key={Math.random()}>
                  <CheckBoxYellow
                    size="xsmall"
                    label1={t(`baterias.filters.amperaje_selector_label`, {
                      valor: amperaje,
                    })}
                    name={`amperaje_${amperaje}`}
                    key={`amperaje_key_${amperaje}`}
                    value={amperaje}
                    checked={searchParams.filters.amperaje?.includes(amperaje)}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value
                      filterChange(
                        'amperaje',
                        event.target.checked
                          ? [...searchParams.filters.amperaje, Number(value)]
                          : searchParams.filters.amperaje.filter(
                              (item) => item !== Number(value)
                            )
                      )
                    }}
                  />
                </div>
              )
            })}
        </div>
      </ListFilterSection>

      {/* POTENCIA_ARRANQUE */}
      <ListFilterSection
        titleId="baterias.filters.potencia"
        expanded={filters.potenciaExpanded}
        toggleSection={() => toggleFilterSection('potenciaExpanded')}>
        <div className={styles.filterSidebar__inputList}>
          {filters &&
            filters.potencia_arranque?.map((potencia) => {
              return (
                <div
                  className={styles.filterSidebar__label}
                  key={Math.random()}>
                  <CheckBoxYellow
                    size="xsmall"
                    label1={t(`baterias.filters.potencia_selector_label`, {
                      valor: potencia,
                    })}
                    name="potencia"
                    key={`potencia_${potencia}`}
                    value={potencia}
                    checked={searchParams.filters.potencia_arranque?.includes(
                      potencia
                    )}
                    onChange={(event) => {
                      const value = event.target.value
                      filterChange(
                        'potencia_arranque',
                        event.target.checked
                          ? [
                              ...searchParams.filters.potencia_arranque,
                              Number(value),
                            ]
                          : searchParams.filters.potencia_arranque.filter(
                              (item) => item !== Number(value)
                            )
                      )
                    }}
                  />
                </div>
              )
            })}
        </div>
      </ListFilterSection>

      {/* POSICION_POSITIVO */}
      <ListFilterSection
        titleId="baterias.filters.bornes"
        expanded={filters.bornesExpanded}
        toggleSection={() => toggleFilterSection('bornesExpanded')}>
        <div className={styles.filterSidebar__inputList}>
          {filters &&
            filters.posicion_positivo?.map((value) => {
              return (
                <div
                  className={styles.filterSidebar__label}
                  key={Math.random()}>
                  <RadioButtonYellow
                    variant="xsmall"
                    label1={t(`baterias.filters.bornes_${value}`)}
                    name="bornes"
                    key={`bornes_${value}`}
                    value={value}
                    checked={searchParams.filters.posicion_positivo === value}
                    onChange={(value) =>
                      filterChange('posicion_positivo', value as BornesBateria)
                    }
                  />
                </div>
              )
            })}
        </div>
      </ListFilterSection>
    </>
  )
}

export default BateriasFilter
