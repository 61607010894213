import {
  CatalogoFilters,
  CatalogoSearchParams,
  FilterSection,
} from '../../../shared/catalogo/type/filter'
import { PAGE_SIZE } from '../../../shared/catalogo/store/list/types.d'

export interface FilterBateriasSections extends FilterSection {
  marcasExpanded: boolean
  startStopExpanded: boolean
  tipoExpanded: boolean
  amperajeExpanded: boolean
  potenciaExpanded: boolean
  bornesExpanded: boolean
}

export interface BateriasFilters extends CatalogoFilters {
  amperaje: number[]
  marca: string[]
  posicion_positivo: string
  potencia_arranque: number[]
  startStop: boolean
  tipo_bateria: string
}

export const initialBateriaFilterValues: CatalogoSearchParams<BateriasFilters> =
  {
    filters: {
      marca: [],
      startStop: null,
      amperaje: [],
      posicion_positivo: null,
      potencia_arranque: [],
      tipo_bateria: null,
    },
    pagination: {
      page: 1,
      pageSize: PAGE_SIZE,
    },
    order: {
      label: 'Recommended',
      value: 'orden.asc',
      field: 'orden',
      direction: 'ASC',
    },
  }

export const initialBateriaFilterSections: FilterBateriasSections = {
  marcasExpanded: true,
  startStopExpanded: true,
  tipoExpanded: true,
  amperajeExpanded: true,
  potenciaExpanded: true,
  bornesExpanded: true,
}
