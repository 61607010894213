import { Producto } from '../../../shared/catalogo/producto/producto'
import { locale, t } from '../../../common/i18n'
import { route } from '../../../common/utils/route-server'
import { slugifyFichaUrl } from '../../../shared/catalogo/utils/slugifyFichaUrl'

export const mapBateriaToProducto = (bateria: Producto): Producto => {
  const nombre = bateria[`nombre_producto_${locale}`]
  const nombreSlug = slugifyFichaUrl(nombre)
  const marcaSlug = slugifyFichaUrl(bateria.marca.nombre)
  const generatedRouteUrl = route('baterias.ficha', {
    marca: marcaSlug,
    nombre: nombreSlug,
    id_site_producto: bateria.id_site_producto,
  })
  return {
    ...bateria,
    ficha_url: generatedRouteUrl,
    specifications: [
      {
        label: t('baterias.list.elemento.tension'),
        value: `${bateria.voltaje} V`,
      },
      {
        label: t('baterias.list.elemento.bornes'),
        value: bateria.posicion_positivo,
      },
      {
        label: t('baterias.list.elemento.amperaje'),
        value: `${bateria.amperaje} Ah`,
      },
      {
        label: t('baterias.list.elemento.potencia_arranque'),
        value: `${bateria.potencia_arranque} A`,
      },
    ],
    tipo: 'bateria',
  }
}
